<template>
  <v-container>
    <v-row>
      <v-col cols="10" sm="10">
        <va-form>
          <div class="main-page">
            <div class="main-page__content">
              <h3 class="main-page__title">title</h3>
              <v-text-field v-model="main.title" type="text"></v-text-field>
            </div>
            <div class="main-page__content">
              <h3 class="main-page__title">subtitle</h3>
              <!-- <v-textarea v-model="main.subtitle" type="text"></v-textarea> -->
              <va-rich-text-input v-model="main.subtitle"></va-rich-text-input>
            </div>
            <div class="main-page__content">
              <h3 class="main-page__title">image</h3>
              <img class="main-page__image" :src="main.image" />
            </div>
            <v-file-input
              v-model="mainImage"
              label="Загрузить фотографии"
              counter
              multiple
              accept="image/*"
              prepend-icon="mdi-camera"
              return-object
              @change="onPhotoChange(mainImage)"
            ></v-file-input>
          </div>
          <v-btn class="about-us__save" depressed color="primary" @click="save">
            Сохранить
          </v-btn>
        </va-form>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "AboutForm",
  components: {},
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      main: null,
      mainImage: [],
      missionImage: [],
    };
  },
  mounted() {
    this.main = this.item;
  },
  methods: {
    save() {
      this.$admin.http.put("/main-page-block", this.main).then(() => {
        this.$emit("saved");
      });
    },
    onPhotoChange() {
      this.mainImage.forEach((file) => {
        const reader = new FileReader();
        reader.onload = () => {
          this.main.image = reader.result;
        };
        reader.readAsDataURL(file);
      });
    },
    getImage(file) {
      return file
        ? file
        : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTAKthpmQNa--6ZneTDfAi5GgZxQEn16OOPGQ&usqp=CAU";
    },
  },
};
</script>

<style lang="scss">
.about-us {
  &__item {
    & + & {
      margin-top: 40px;
    }
  }
  &__save {
    margin-top: 30px;
  }
  &__title {
    font-size: 22px;
    line-height: 120%;
    margin-bottom: 30px;
    &-values {
      font-size: 20px;
      margin-bottom: 10px;
    }
  }
  &__img {
    width: 200px;
    height: 200px;
    object-fit: cover;
  }
}
</style>
