import Vue from "vue";
import VuetifyAdmin from "vuetify-admin";
// import { basicAuthProvider } from "vuetify-admin/src/providers";

import "vuetify-admin/src/loader";

import dataProvider from "@/providers/dataProvider";
import authProvider from "@/providers/authProvider";

import {} from "vuetify-admin/src/providers";
// import { en } from "vuetify-admin/src/locales";
import { ru } from "../locales";

import router from "@/router";
import routes from "@/router/admin";
import store from "@/store";
import i18n from "@/i18n";
import resources from "@/resources";
import axios from "axios";

/**
 * Load Admin UI components
 */
Vue.use(VuetifyAdmin);

/**
 * Axios instance
 */
const baseURL =
  process.env.VUE_APP_API_URL || "https://api.megarent.kz/api/admin";

const token = store.auth?.user?.token
  ? store.auth.user.token
  : localStorage.getItem("token");
console.log(store.state);
const headers = {
  "X-Requested-With": "XMLHttpRequest",
  Accept: "applicatoin/json",
};

if (token) {
  headers["Authorization"] = `Bearer ${token}`;
}

const http = axios.create({
  baseURL,
  headers,
});

/**
 * Init admin
 */
export default new VuetifyAdmin({
  router,
  store,
  i18n,
  title: "Megarent Admin",
  routes,
  locales: {
    ru,
  },
  translations: ["ru"],
  dataProvider: dataProvider(http),
  authProvider: authProvider(http),
  resources,
  http,
  options: {
    dateFormat: "long",
  },
});
