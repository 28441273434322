<template>
  <va-form :id="id" :item="item" disable-redirect @saved="$emit('saved')">
    <v-text-field
      label="title"
      source="title"
      v-model="tariff.title"
      required
    ></v-text-field>
    <v-text-field
      source="price"
      v-model="tariff.price"
      label="price"
      type="text"
      required
    ></v-text-field>
    <div class="tariff__services">
      <h3>Выберите услуги для тарифа</h3>
      <div
        v-for="(service, index) in services"
        :key="index"
        :class="{
          active: tariffServicesIds.find((e) => e === service.id),
        }"
      >
        <p @click="getTariffServicesIds(service)">{{ service.title }}</p>
      </div>
    </div>
    <v-btn class="slider__save-btn" color="blue" @click.prevent="save">
      mdi-save
    </v-btn>
  </va-form>
</template>

<script>
export default {
  props: ["id", "item", "action"],
  data() {
    return {
      tariff: { title: "", price: "", services: [] },
      rawImage: null,
      isConvertDone: false,
      services: [],
      tariffServicesIds: [],
    };
  },
  created() {
    this.tariff = { ...this.item };
    if (this.tariff?.services?.length) {
      this.tariff.services.forEach((e) => {
        this.tariffServicesIds.push(e.id);
      });
    }

    console.log("tariff", this.tariff);
    this.getAllTariffServices();
  },
  mounted() {
    if (!this.item) {
      this.tariff = { title: "", price: "", services: [] };
    }
  },
  methods: {
    save() {
      let tariff = {
        title: this.tariff.title,
        price: this.tariff.price,
        service_ids: this.tariffServicesIds,
      };

      if (!this.item) {
        this.$admin.http
          .post("/tariffs", tariff)
          .then(() => {
            this.$emit("saved");
          })
          .catch((e) => {
            this.$store.commit("messages/showToast", {
              color: "red",
              message: e.response.data.message,
            });
          });
        return;
      }
      this.$admin.http.put(`/tariffs/${this.item.id}`, tariff).then(() => {
        this.$emit("saved");
      });
    },
    getAllTariffServices() {
      this.$admin.http.get("/services").then((response) => {
        this.services = response.data.data.data;
        console.log("services", this.services);
      });
    },
    getTariffServicesIds(service) {
      console.log(this.tariffServicesIds.indexOf(service.id) === -1);
      if (this.tariffServicesIds.indexOf(service.id) === -1) {
        this.tariffServicesIds.push(service.id);
      } else {
        this.tariffServicesIds.splice(
          this.tariffServicesIds.indexOf(service.id),
          1
        );
      }
    },
    covertImageToBase64() {
      console.log("DDDD", this.rawImage);
      const file = this.rawImage;
      const reader = new FileReader();
      let base64Str = "";

      reader.onload = () => {
        base64Str = reader.result;
        // console.log(base64Str)
        this.slider.image = base64Str;
        console.log(this.slider.image);
        this.isConvertDone = true;
      };
      reader.readAsDataURL(file);
    },
  },
};
</script>

<style lang="scss" scoped>
.slider__save {
  &-btn {
    margin-top: 20px;
  }
}
.tariff__services {
  h3 {
    margin-bottom: 20px;
  }
  div {
    display: block;
    p {
      display: inline-block;
      padding-right: 70px;
      cursor: pointer;
    }
    &.active {
      p {
        color: red;
        position: relative;
        &::after {
          content: "выбран";
          color: green;
          font-size: 14px;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          right: 0;
        }
      }
    }
  }
}
</style>
