<template>
  <div>
    <app-mobile-form
      v-if="isUpdateMobileApp"
      :item="mobileApp[0]"
      @saved="isUpdateMobileApp = false"
    ></app-mobile-form>
    <base-material-card v-else :icon="resource.icon" :title="'Main Page'">
      <v-container>
        <div v-for="(item, index) in mobileApp" :key="index" class="main-page">
          <div class="main-page__content">
            <h5 class="main-page__title">title</h5>
            <p class="main-page__text">{{ item.title }}</p>
          </div>
          <div class="main-page__content">
            <h5 class="main-page__title">subtitle</h5>
            <p class="main-page__text">{{ item.subtitle }}</p>
          </div>
          <div class="main-page__content">
            <h5 class="main-page__title">qr</h5>
            <img class="main-page__image" :src="item.qr" alt="" />
          </div>
          <div class="main-page__content">
            <h5 class="main-page__title">app_store</h5>
            <p class="main-page__text">{{ item.app_store }}</p>
          </div>
          <div class="main-page__content">
            <h5 class="main-page__title">google_play</h5>
            <p class="main-page__text">{{ item.google_play }}</p>
          </div>
        </div>
        <v-btn color="primary" @click="updateAbout">Изменить</v-btn>
      </v-container>
    </base-material-card>
  </div>
</template>

<script>
export default {
  components: {},
  props: ["resource", "title"],
  data() {
    return {
      mobileApp: [],
      isUpdateMobileApp: false,
    };
  },
  computed: {},
  watch: {
    isUpdateMobileApp: {
      handler() {
        window.scrollTo(0, 0);
      },
    },
  },
  created() {
    this.$admin.http.get("/mobile-app").then((response) => {
      console.log(response.data.data.data);
      this.mobileApp = response.data.data.data;
    });
  },
  methods: {
    updateAbout() {
      this.isUpdateMobileApp = true;
    },
    onSaved() {
      this.asideTitle = null;
      this.$refs.list.fetchData();
    },
  },
};
</script>

<style lang="scss">
.main-page {
  padding-top: 20px;
  &__content {
    margin-bottom: 30px;
  }

  &__title {
    font-size: 18px;
    line-height: 120%;
    margin-bottom: 5px;
  }

  &__text {
    font-size: 20px;
    line-height: 120%;
  }

  &__image {
    width: 500px;
    height: 500px;
  }
}
</style>
