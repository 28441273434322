<template>
  <div>
    <create-form
      :action="action"
      :item="item"
      @save="save"
      @update="update"
      section="special-equipment"
    />

    <div class="text-center ma-2">
      <v-snackbar :dark="dark" v-model="snackbar">
        {{ message }}

        <template v-slot:action="{ attrs }">
          <v-btn color="pink" text v-bind="attrs" @click="snackbar = false">
            Close
          </v-btn>
        </template>
      </v-snackbar>
    </div>
  </div>
</template>

<script>
export default {
  props: ["id", "item", "action"],
  components: {
    CreateForm: () => import("@/components/megarent/forms/CreateForm.vue"),
  },
  data() {
    return {
      snackbar: false,
      message: "",
      dark: false,
    };
  },
  methods: {
    save(data) {
      this.$admin.http
        .post(data.route, data.data)
        .then((response) => {
          this.$emit("saved");
          this.snackbar = true;
          this.message = response.data.status;
        })
        .catch((e) => {
          this.snackbar = true;
          this.message = e;
        });
    },
    update(data) {
      this.$admin.http
        .put(data.route, data.data)
        .then((response) => {
          this.$emit("saved");
          this.snackbar = true;
          this.message = response.data.status ? "Обновлено" : "";
        })
        .catch((e) => {
          this.snackbar = true;
          this.message = e;
        });
    },
  },
};
</script>
