<template>
  <va-form :id="id" :item="item" disable-redirect @saved="$emit('saved')">
    <va-text-input source="instagram"></va-text-input>
    <va-text-input source="facebook"></va-text-input>
    <va-text-input source="vk"></va-text-input>
    <va-text-input source="telegram"></va-text-input>

    <va-save-button v-if="isShowSaveButton"></va-save-button>
  </va-form>
</template>

<script>
export default {
  props: ["id", "item", "action"],

  computed: {
    isShowSaveButton() {
      return this.action !== "show";
    },
  },
};
</script>
