export default [
  {
    name: "users",
    icon: "mdi-account",
    routes: ["list"],
  },
  {
    name: "special-equipment",
    icon: "mdi-tow-truck",
    routes: ["list"],
    text: "Спецтехника",
  },
  {
    name: "material",
    icon: "mdi-store",
    routes: ["list"],
  },
  {
    name: "vacancy",
    icon: "mdi-account-box-multiple",
    routes: ["list"],
  },
  // {
  //   name: "regions",
  //   icon: "mdi-earth",
  //   routes: ["list"],
  // },
  {
    name: "material-categories",
    icon: "mdi-view-split-vertical",
    routes: ["list"],
  },
  {
    name: "material-subcategories",
    icon: "mdi-view-split-vertical",
    routes: ["list"],
  },
  {
    name: "material-units",
    icon: "mdi-view-split-vertical",
    routes: ["list"],
  },
  {
    name: "special-equipment-categories",
    icon: "mdi-view-split-vertical",
    routes: ["list"],
  },
  {
    name: "special-equipment-marks",
    icon: "mdi-view-split-vertical",
    routes: ["list"],
  },
  {
    name: "vacancy-positions",
    icon: "mdi-view-split-vertical",
    routes: ["list"],
  },
  {
    name: "special-equipment-subcategories",
    icon: "mdi-view-split-vertical",
    routes: ["list"],
  },
  {
    name: "news",
    icon: "mdi-chart-tree",
    routes: ["list"],
  },
  {
    name: "main-news",
    icon: "mdi-chart-tree",
    routes: ["list"],
  },
  {
    name: "filters",
    icon: "mdi-chart-tree",
    routes: ["list"],
  },
  {
    name: "contact-us",
    icon: "mdi-phone",
    routes: ["list"],
  },
  {
    name: "vacancy-rights",
    icon: "mdi-book",
    routes: ["list"],
  },
  {
    name: "contact",
    icon: "mdi-phone",
    routes: ["list"],
  },
  {
    name: "social-network",
    icon: "mdi-facebook",
    routes: ["list"],
  },
  {
    name: "vacancy-technicians",
    icon: "mdi-car",
    routes: ["list"],
  },
  {
    name: "vacancy-employments",
    icon: "mdi-arrow-up",
    routes: ["list"],
  },
  {
    name: "about",
    icon: "mdi-alert-circle-outline",
    routes: ["list"],
  },
  {
    name: "clients",
    icon: "mdi-account",
    routes: ["list"],
  },
  {
    name: "main-page",
    icon: "mdi-alert-circle-outline",
    routes: ["list"],
  },
  {
    name: "slider",
    icon: "mdi-alert-circle-outline",
    routes: ["list"],
  },
  {
    name: "top",
    icon: "mdi-alert-circle-outline",
    routes: ["list"],
  },
  {
    name: "app-mobile",
    icon: "mdi-alert-circle-outline",
    routes: ["list"],
  },
  {
    name: "seo",
    icon: "mdi-card-search-outline",
    routes: ["list"],
  },
  {
    name: "privacy-policy",
    icon: "mdi-card-search-outline",
    routes: ["list"],
  },
  {
    name: "offer-agreement",
    icon: "mdi-card-search-outline",
    routes: ["list"],
  },
  {
    name: "tariffs",
    icon: "mdi-card-search-outline",
    routes: ["list"],
  },
  {
    name: "services",
    icon: "mdi-card-search-outline",
    routes: ["list"],
  },
];
