<template>
  <va-form
    :id="id"
    :item="item"
    disable-redirect
    @saved="$emit('saved')"
    ref="form"
  >
    <v-col col="12" sm="6">
      <v-file-input
        v-model="file"
        label="Загрузить файл"
        counter
        multiple
        prepend-icon="mdi-camera"
        return-object
      ></v-file-input>
    </v-col>
    <v-btn class="slider__save-btn" color="blue" @click.prevent="save"
      >сохранить</v-btn
    >
  </va-form>
</template>

<script>
export default {
  props: ["id", "item", "action"],
  data() {
    return {
      file: null,
    };
  },
  methods: {
    save() {
      console.log(this.file[0]);
      let formData = new FormData();
      formData.append("file", this.file[0]);
      this.$admin.http
        .post("/privacy-policy", formData)
        .then(() => {
          this.$emit("saved");
        })
        .catch((e) => {
          this.$store.commit("messages/showToast", {
            color: "red",
            message: e.response.data.message,
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.slider__save {
  &-btn {
    margin-top: 20px;
  }
}
</style>
