<template>
  <v-container>
    <v-row>
      <v-col v-if="aboutUs" cols="10" sm="10">
        <va-form>
          <div class="about-us__item">
            <h3 class="about-us__title">main</h3>
            <v-text-field
              v-model="aboutUs.main.title"
              label="main title"
              type="text"
            ></v-text-field>

            <v-text-field
              v-model="aboutUs.main.subtitle"
              label="main subtitle"
              type="text"
            ></v-text-field>
            <img class="about-us__img" :src="aboutUs.main.image" />
            <v-file-input
              v-model="mainImage"
              label="Загрузить фотографии"
              counter
              multiple
              accept="image/*"
              prepend-icon="mdi-camera"
              return-object
              @change="onPhotoChange({ images: mainImage, about: 'main' })"
            ></v-file-input>
            <va-rich-text-input
              v-model="aboutUs.main.text"
              label="main text"
            ></va-rich-text-input>
          </div>

          <div class="about-us__item">
            <h3 class="about-us__title">mission</h3>
            <img class="about-us__img" :src="aboutUs.mission.image" />
            <v-file-input
              v-model="missionImage"
              label="Загрузить фотографии"
              counter
              multiple
              accept="image/*"
              prepend-icon="mdi-camera"
              return-object
              @change="
                onPhotoChange({ images: missionImage, about: 'mission' })
              "
            ></v-file-input>

            <editor v-model="aboutUs.mission.text" label="mission text" />
          </div>

          <div class="about-us__item">
            <h3 class="about-us__title">values</h3>
            <div v-for="(item, index) in aboutUs.values" :key="index">
              <h6 class="about-us__title-values">{{ index + 1 }}</h6>
              <img class="about-us__img" :src="getImage(item.image)" />
              <v-file-input
                v-model="item.images"
                label="Загрузить фотографии"
                counter
                multiple
                accept="image/*"
                prepend-icon="mdi-camera"
                return-object
                @change="onPhotoChangeValue(item)"
              ></v-file-input>
              <v-text-field
                v-model="item.title"
                label="title"
                type="text"
              ></v-text-field>
              <editor v-model="item.text" label="mission text" />
            </div>
            <v-btn
              style="margin-top: 20px"
              elevation="2"
              small
              color="secondary"
              @click="addValues"
              >Добавить рабочее место</v-btn
            >
          </div>

          <v-btn class="about-us__save" depressed color="primary" @click="save">
            Сохранить
          </v-btn>
        </va-form>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Editor from "@tinymce/tinymce-vue";
export default {
  name: "AboutForm",
  props: {
    about: {
      type: Object,
      required: true,
    },
  },
  components: {
    // eslint-disable-next-line vue/no-unused-components
    Editor,
  },
  data() {
    return {
      aboutUs: null,
      mainImage: [],
      missionImage: [],
    };
  },
  created() {
    this.$admin.http.get("/about-us").then((response) => {
      this.aboutUs = response.data.data.data;
      // console.log("about-us", response.data.data.data);
    });
  },
  methods: {
    addValues() {
      this.aboutUs.values.push({
        image: "",
        title: "",
        text: "",
        imageValues: null,
      });
    },
    save() {
      this.$admin.http.put("/about-us", this.aboutUs).then(() => {
        this.$emit("saved");
      });
      console.log(this.aboutUs);
    },
    mapImagesToObject(arr) {
      const images = arr.map((imageUrl) => {
        return { link: imageUrl };
      });
      return images;
    },

    onPhotoChange(item) {
      item.images.forEach((file) => {
        const reader = new FileReader();
        reader.onload = () => {
          switch (item.about) {
            case "main":
              this.aboutUs.main.image = reader.result;
              break;
            case "mission":
              this.aboutUs.mission.image = reader.result;
              break;
          }
          // item.about = reader.result;
        };
        reader.readAsDataURL(file);
      });
    },
    onPhotoChangeValue(item) {
      item.images.forEach((file) => {
        const reader = new FileReader();
        reader.onload = () => {
          item.image = reader.result;
        };
        reader.readAsDataURL(file);
      });
      console.log(this.aboutUs.values);
    },

    getImage(file) {
      return file
        ? file
        : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTAKthpmQNa--6ZneTDfAi5GgZxQEn16OOPGQ&usqp=CAU";
    },
  },
};
</script>

<style lang="scss">
.about-us {
  &__item {
    & + & {
      margin-top: 40px;
    }
  }
  &__save {
    margin-top: 30px;
  }
  &__title {
    font-size: 22px;
    line-height: 120%;
    margin-bottom: 30px;
    &-values {
      font-size: 20px;
      margin-bottom: 10px;
    }
  }
  &__img {
    width: 200px;
    height: 200px;
    object-fit: cover;
  }
}
</style>
