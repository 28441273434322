<template>
  <div>
    <va-show :item="item">
      <va-field source="title"></va-field>
      <va-field source="description"></va-field>
      <va-field source="term"></va-field>
      <va-field source="price"></va-field>
      <va-field source="slug"></va-field>
      <va-field source="is_show"></va-field>
      <p>{{ item.is_show ? "Показывается" : "не показывается" }}</p>
      <v-img v-if="item" :src="item.image" />
    </va-show>
  </div>
</template>

<script>
export default {
  props: ["item"],
};
</script>
