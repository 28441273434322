<template>
  <v-container>
    <v-row>
      <v-col cols="10" sm="10">
        <va-form>
          <div class="main-page__content">
            <v-file-input
              v-model="file"
              label="Загрузить файл"
              counter
              multiple
              prepend-icon="mdi-camera"
              return-object
            ></v-file-input>
          </div>

          <v-btn class="about-us__save" depressed color="primary" @click="save">
            Сохранить
          </v-btn>
        </va-form>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "AboutForm",
  components: {},

  data() {
    return {
      file: null,
      formData: new FormData(),
    };
  },

  methods: {
    save() {
      this.formData.append("file", this.file[0]);
      this.$admin.http.post("/offer-agreement", this.formData).then(() => {
        this.$emit("saved");
      });
    },
    onFileInputChange() {
      this.formData.append("file", this.file);
    },
  },
};
</script>

<style lang="scss">
.about-us {
  &__item {
    & + & {
      margin-top: 40px;
    }
  }
  &__save {
    margin-top: 30px;
  }
  &__title {
    font-size: 22px;
    line-height: 120%;
    margin-bottom: 30px;
    &-values {
      font-size: 20px;
      margin-bottom: 10px;
    }
  }
  &__img {
    width: 200px;
    height: 200px;
    object-fit: cover;
  }
}
</style>
