<template>
  <va-show :item="item">
    <a :href="file" target="_blank">посмотреть</a>
    <v-img v-if="item" :src="item.image" />
  </va-show>
</template>

<script>
export default {
  props: ["item"],
  data() {
    return {
      file: null,
    };
  },
  created() {
    console.log("item", this.item);
    this.file = this.item?.data?.data?.data[0].file;
  },
};
</script>
