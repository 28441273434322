<template>
  <div>
    <va-show :item="item">
      <va-field source="title"></va-field>
      <va-field source="price"></va-field>
      <h3>services</h3>
      <v-row>
        <v-col v-if="!isEdit" col="12" sm="6">
          <div v-for="(item, index) in item.services" :key="index">
            <va-field source="id"></va-field>
            <va-field source="slug"></va-field>
            <va-field source="title"></va-field>
            <v-img v-if="item" :src="item.image" />
          </div>
        </v-col>
      </v-row>
    </va-show>
  </div>
</template>

<script>
export default {
  props: ["item"],
};
</script>
