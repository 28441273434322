/* eslint-disable no-unused-vars */

export default (i18n, admin) => [
  // {
  //   icon: "mdi-view-dashboard",
  //   text: i18n.t("menu.dashboard"),
  //   link: "/",
  // },
  { divider: true },

  admin.getResourceLink("special-equipment"),
  admin.getResourceLink("special-equipment-categories"),
  admin.getResourceLink("special-equipment-subcategories"),
  admin.getResourceLink("special-equipment-marks"),

  admin.getResourceLink("material"),
  admin.getResourceLink("material-categories"),
  admin.getResourceLink("material-subcategories"),
  admin.getResourceLink("material-units"),

  admin.getResourceLink("vacancy"),
  admin.getResourceLink("vacancy-positions"),
  admin.getResourceLink("vacancy-rights"),
  admin.getResourceLink("vacancy-technicians"),
  admin.getResourceLink("vacancy-employments"),
  // admin.getResourceLink("regions"),

  admin.getResourceLink("filters"),
  admin.getResourceLink("news"),
  admin.getResourceLink("main-news"),
  admin.getResourceLink("contact-us"),
  admin.getResourceLink("users"),
  admin.getResourceLink("contact"),
  admin.getResourceLink("social-network"),
  admin.getResourceLink("about"),
  admin.getResourceLink("clients"),
  admin.getResourceLink("main-page"),
  admin.getResourceLink("slider"),
  admin.getResourceLink("top"),
  admin.getResourceLink("app-mobile"),
  admin.getResourceLink("seo"),
  admin.getResourceLink("privacy-policy"),
  admin.getResourceLink("offer-agreement"),
  admin.getResourceLink("tariffs"),
  admin.getResourceLink("services"),
];
