<template>
  <va-form :id="id" :item="item" disable-redirect @saved="$emit('saved')">
    <v-text-field
      label="email"
      source="email"
      v-model="contact.email"
      required
    ></v-text-field>
    <v-text-field
      source="address"
      v-model="contact.address"
      label="address"
      type="text"
      required
    ></v-text-field>
    <v-text-field
      source="phone_number"
      v-model="contact.phone_number"
      label="subtitle"
      type="number"
      required
    ></v-text-field>
    <v-file-input
      accept="image/*"
      v-model="rawImage"
      @change="covertImageToBase64"
    />
    <v-img v-if="item" :src="item.image" />

    <v-btn color="blue" @click.prevent="save">save</v-btn>
  </va-form>
  <!-- <va-form :id="id" :item="item" disable-redirect @saved="$emit('saved')">
    <va-text-input source="email"></va-text-input>
    <va-text-input source="address"></va-text-input>
    <va-text-input source="phone_number"></va-text-input>
    <v-file-input
      accept="image/*"
      v-model="rawImage"
      @change="covertImageToBase64"
    />
    <v-img v-if="item" :src="item.image" />
    <v-btn color="blue" @click.prevent="save">mdi-save</v-btn>

    <va-save-button v-if="isShowSaveButton"></va-save-button>
  </va-form> -->
</template>

<script>
export default {
  props: ["id", "item"],
  data() {
    return {
      contact: { email: "", address: "", phone_number: "", image: "" },
      rawImage: null,
      isConvertDone: false,
    };
  },
  created() {
    this.contact = { ...this.item };
  },
  methods: {
    save() {
      let updatedData = this.contact;
      console.log("item", this.item);
      if (!this.rawImage) {
        this.contact.image = this.item.image;
        updatedData = this.contact;
      } else {
        updatedData = this.contact;
      }
      console.log("contact", updatedData);
      this.$admin.http.put(`/contact`, updatedData).then(() => {
        this.$emit("saved");
      });

      // this.$emit("saved", this.title);
    },
    covertImageToBase64() {
      const file = this.rawImage;
      const reader = new FileReader();
      let base64Str = "";
      reader.onload = () => {
        base64Str = reader.result;
        this.contact.image = base64Str;
        this.isConvertDone = true;
      };
      reader.readAsDataURL(file);
    },
  },
};
</script>
