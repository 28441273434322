<template>
  <div>
    <create-filter-form @saved="$emit('saved')" :item="item" />
  </div>
</template>

<script>
export default {
  props: ["id", "item"],
  components: {
    CreateFilterForm: () =>
      import("@/components/megarent/forms/CreateFilterForm.vue"),
  },
  mounted() {
    console.log("lol", this.item);
  },
  methods: {},
};
</script>
