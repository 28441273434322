<template>
  <va-form :id="id" :item="item" disable-redirect @saved="$emit('saved')">
    <v-select v-model="metaInfo.slug" :items="slugsNotFilled" label="Для страницы"></v-select>
    <v-text-field v-model="metaInfo.title" label="Название страницы"></v-text-field>
    <v-text-field  v-model="metaInfo.description" label="Описание (description)"></v-text-field>
    <v-text-field  v-model="metaInfo.content" label="Ключевые слова (keywords)"></v-text-field>
    <v-btn color="blue" @click.prevent="save">
      <v-icon>mdi-content-save</v-icon>
      Сохранить
    </v-btn>
  </va-form>
</template>

<script>
export default {
  props: ["id", "item"],
  data() {
    return {
      metaInfo: { title: "", description: "", content: "", slug: "" },
      //Front route names
      slugs:[
        "index",
        "top-announcement",
        "contacts",
        "about",
        "contacts",
      ],
      slugsExists:[],
    };
  },
  computed:{
    slugsNotFilled(){
      return this.item ? this.slugs : this.slugs.filter(s=>!this.slugsExists.includes(s))
    }
  },
  created() {
    this.initMetaInfo();
    this.getAllSlugs();

  },
  methods: {
    initMetaInfo(){
      if(this.item){
        this.metaInfo = { ...this.item };
        return
      }
      console.log("CREATED")
      this.metaInfo = { title: "", description: "", content: "", slug: "" }

    },

    getAllSlugs(){
      this.$admin.http.get('/seo').then((response)=>{
        console.log(response.data.data)
        this.slugsExists = response.data.data.data?.map(s=>s.slug);
      })
    },

    save() {
      if (!this.item) {
        this.$admin.http
          .post("/seo", this.metaInfo)
          .then(() => {
            this.$emit("saved");
          })
          .catch((e) => {
            this.$store.commit("messages/showToast", {
              color: "red",
              message: e.response.data.message,
            });
          });
        return;
      }
      let updatedData = this.metaInfo;

      this.$admin.http.put(`/news/${this.item.id}`, updatedData).then(() => {
        this.$emit("saved");
      });
    },
  },
};
</script>
