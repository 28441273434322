<template>
  <router-view :key="$route.fullpath" />
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "App",
  computed: {
    ...mapState("auth", ["user"]),
  },
  watch: {
    user: {
      deep: true,
      handler() {
        if (this.user?.token) {
          this.$admin.http.defaults.headers.common[
            "Authorization"
          ] = `Bearer ${this.user?.token}`;
        }
      },
    },
  },
};
</script>
