<template>
  <v-container>
    <v-row>
      <v-col cols="12" sm="4">{{ item.title }}</v-col>
      <v-col cols="12" sm="4">{{ item.category_material }}</v-col>
      <v-col cols="12" sm="4">{{ item.category_special_equipment }}</v-col>
      <v-col
        v-for="element in item.elements"
        :key="element.id"
        cols="12"
        sm="3"
      >
        {{ element.value }}
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  props: ["item"],
};
</script>
