<template>
  <va-form :id="id" :item="item" disable-redirect @saved="$emit('saved')">
    <v-select
      v-model="selectCategory"
      :items="categories"
      chips
      label="Выбрать марку"
      item-text="value"
      solo
    ></v-select>
    <v-autocomplete
      v-if="selectCategory"
      :items="select"
      v-model="selectedItem"
      item-text="title"
      item-value="id"
      return-object
      chips
      label="Выбрать объявление"
      solo
    ></v-autocomplete>
    <p v-if="isNotFilled" style="margin: 0; color: red">заполните term</p>
    <v-text-field
      source="term"
      v-model="term"
      label="term"
      type="number"
      required
    ></v-text-field>
    <v-btn class="slider__save-btn" color="blue" @click="save">mdi-save</v-btn>
  </va-form>
</template>

<script>
export default {
  props: ["id", "item", "action"],
  data() {
    return {
      top: null,
      categories: [
        {
          value: "special-equipment",
        },
        {
          value: "material",
        },
        {
          value: "vacancy",
        },
      ],
      selectCategory: null,
      select: [],
      selectedItem: null,
      term: "",
      isNotFilled: false,
    };
  },
  created() {
    console.log("top", this.item);

    this.$admin.http.get(`users?sort[]={"by":"id","desc":true}`).then((res) => {
      this.users = res.data.data.data;
      console.log(this.users);
    });
    if (this.item) {
      console.log("item", this.item);
      this.selectCategory = this.categories.find(
        (e) => e.value === this.item.type
      );
    }
  },
  watch: {
    term: {
      handler() {
        if (this.term) {
          this.isNotFilled = false;
        } else {
          this.isNotFilled = true;
        }
      },
    },
    selectCategory: {
      handler() {
        this.$admin.http.get(`/${this.selectCategory}`).then((res) => {
          this.select = res.data.data.data;
          console.log("select", this.select);
        });
      },
    },
  },
  methods: {
    save() {
      if (this.term === "") {
        this.isNotFilled = true;
        return;
      } else {
        this.isNotFilled = false;
        let top = {
          id: this.item?.id,
          announcement_id: this.selectedItem.id,
          type: this.selectCategory,
          term: this.term,
        };
        if (!this.item) {
          this.$admin.http
            .post("/top", top)
            .then(() => {
              this.$emit("saved");
            })
            .catch((e) => {
              this.$store.commit("messages/showToast", {
                color: "red",
                message: e.response.data.message,
              });
            });
          return;
        }
        this.$admin.http.put(`/top/${this.item.id}`, top).then(() => {
          this.$emit("saved");
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.slider__save {
  &-btn {
    margin-top: 20px;
  }
}
</style>
