<template>
  <v-container>
    <v-row>
      <v-col v-if="newsMain" cols="10" sm="10">
        <p>lol</p>
        <va-form>
          <div class="about-us__item">
            <h3 class="about-us__title">block</h3>
            <img class="about-us__img" :src="getImage(newsMain.block.image)" />
            <v-file-input
              v-model="mainImage"
              label="Загрузить фотографии"
              counter
              multiple
              accept="image/*"
              prepend-icon="mdi-camera"
              return-object
              @change="onPhotoChange({ images: mainImage, about: 'main' })"
            ></v-file-input>
            <v-text-field
              v-model="newsMain.block.title"
              label="main title"
              type="text"
            ></v-text-field>
            <v-text-field
              v-model="newsMain.block.subtitle"
              label="main subtitle"
              type="text"
            ></v-text-field>
            <v-text-field
              v-model="newsMain.block.link"
              label="main subtitle"
              type="text"
            ></v-text-field>
          </div>

          <div class="about-us__item">
            <v-text-field
              v-model="newsMain.title"
              label="main title"
              type="text"
            ></v-text-field>
            <editor v-model="newsMain.description" label="mission text" />
          </div>
          <v-btn class="about-us__save" depressed color="primary" @click="save">
            Сохранить
          </v-btn>
        </va-form>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Editor from "@tinymce/tinymce-vue";
export default {
  name: "NewsForm",
  props: {
    news: {
      type: Object,
      required: true,
    },
  },
  components: {
    // eslint-disable-next-line vue/no-unused-components
    Editor,
  },
  data() {
    return {
      newsMain: null,
      mainImage: [],
      missionImage: [],
    };
  },
  mounted() {
    this.newsMain = this.news;
  },
  methods: {
    save() {
      this.$admin.http.put("/news-main", this.newsMain).then(() => {
        this.$emit("saved");
      });
    },
    mapImagesToObject(arr) {
      const images = arr.map((imageUrl) => {
        return { link: imageUrl };
      });
      return images;
    },

    onPhotoChange(item) {
      item.images.forEach((file) => {
        const reader = new FileReader();
        reader.onload = () => {
          this.newsMain.block.image = reader.result;
          // item.about = reader.result;
        };
        reader.readAsDataURL(file);
      });
    },
    getImage(file) {
      return file
        ? file
        : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTAKthpmQNa--6ZneTDfAi5GgZxQEn16OOPGQ&usqp=CAU";
    },
  },
};
</script>

<style lang="scss">
.about-us {
  &__item {
    & + & {
      margin-top: 40px;
    }
  }
  &__save {
    margin-top: 30px;
  }
  &__title {
    font-size: 22px;
    line-height: 120%;
    margin-bottom: 30px;
    &-values {
      font-size: 20px;
      margin-bottom: 10px;
    }
  }
  &__img {
    width: 200px;
    height: 200px;
    object-fit: cover;
  }
}
</style>
