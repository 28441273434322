<template>
  <va-form :id="id" :item="item" disable-redirect @saved="$emit('saved')">
    <v-text-field
      label="title"
      source="title"
      v-model="service.title"
      required
    ></v-text-field>
    <v-text-field
      label="description"
      source="description"
      v-model="service.description"
      required
    ></v-text-field>
    <v-text-field
      label="term"
      source="term"
      v-model="service.term"
      type="number"
      required
    ></v-text-field>
    <v-text-field
      source="price"
      v-model="service.price"
      label="price"
      type="number"
      required
    ></v-text-field>
    <v-select
      v-model="service.slug"
      :items="functional"
      chips
      label="Выбрать юзера"
      solo
    ></v-select>
    <div class="service__buttons">
      <button
        class="service__btn"
        :class="{ active: service.is_show === true || service.is_show === 1 }"
        @click.prevent="service.is_show = true"
      >
        Показывать
      </button>
      <button
        class="service__btn"
        @click.prevent="service.is_show = false"
        :class="{ active: service.is_show === false || service.is_show === 0 }"
      >
        Не показывать
      </button>
    </div>
    <v-file-input
      accept="image/*"
      v-model="rawImage"
      @change="covertImageToBase64"
    />
    <v-img v-if="service.image" :src="service.image" />

    <v-btn class="slider__save-btn" color="blue" @click.prevent="save">
      mdi-save
    </v-btn>
  </va-form>
</template>

<script>
export default {
  props: ["id", "item", "action"],
  data() {
    return {
      service: { title: "", price: "", image: "", is_show: false, slug: "" },
      rawImage: null,
      isConvertDone: false,
      functional: [],
    };
  },
  created() {
    this.service = { ...this.item };
    this.getServicesFunctional();
  },
  mounted() {
    if (!this.item) {
      this.service = {
        title: "",
        price: "",
        image: "",
        is_show: false,
        slug: "",
        description: "",
        term: null,
      };
    }
  },
  methods: {
    save() {
      let service = {
        title: this.service.title,
        price: this.service.price,
        image: this.service.image,
        is_show: this.service.is_show,
        slug: this.service.slug,
        description: this.service.description,
        term: this.service.term,
      };
      console.log("service", service);

      if (!this.item) {
        this.$admin.http
          .post("/services", service)
          .then(() => {
            this.$emit("saved");
          })
          .catch((e) => {
            this.$store.commit("messages/showToast", {
              color: "red",
              message: e.response.data.message,
            });
          });
        return;
      }
      this.$admin.http.put(`/services/${this.item.id}`, service).then(() => {
        this.$emit("saved");
      });
    },
    getServicesFunctional() {
      this.$admin.http.get("/services/functional").then((response) => {
        this.functional = response.data.data;
      });
    },
    covertImageToBase64() {
      const file = this.rawImage;
      const reader = new FileReader();
      let base64Str = "";
      reader.onload = () => {
        base64Str = reader.result;
        this.service.image = base64Str;
        console.log(this.service.image);
        this.isConvertDone = true;
      };
      reader.readAsDataURL(file);
    },
  },
};
</script>

<style lang="scss" scoped>
.slider__save {
  &-btn {
    margin-top: 20px;
  }
}

.service__buttons {
  display: flex;
  align-items: center;
  margin-left: 20px;
}
.service__btn {
  padding: 10px;
  font-size: 14px;
  background-color: #fff;
  border: 3px solid #2196f3;
  border-radius: 10px;
  & + & {
    margin-left: 20px;
  }
  &.active {
    background-color: #2196f3;
    color: #fff;
  }
}
</style>
