/* eslint-disable no-unused-vars */

import {
  GET_LIST,
  GET_MANY,
  GET_ONE,
  CREATE,
  UPDATE,
  UPDATE_MANY,
  DELETE,
  DELETE_MANY,
} from "vuetify-admin/src/providers/data/actions";

export default (httpClient) => {
  return {
    [GET_LIST]: async (resource, params) => {
      if (resource === "about") {
        return;
      }

      const response = await httpClient.get(resource, {
        params: {
          page: params?.pagination?.page || 1,
          limit: params?.pagination.perPage,
          ...params,
        },
      });

      switch (resource) {
        case "filters":
          return {
            data: response.data.data.data,
            total: response.data.data.total,
            perPage: response.data.data.per_page,
          };
        case "slider":
          return { data: response.data.data };
        case "top":
          return { data: response.data.data };
        default:
          return {
            data: response.data.data.data,
            total: response.data.data.total,
            perPage: response.data.data.total,
          };
      }
    },

    [GET_MANY]: (resource, params) => ({ data: [] }),

    [GET_ONE]: async (resource, params) => {
      let response = null;

      if (
        resource === "special-equipment" ||
        resource === "material" ||
        resource === "vacancy"
      ) {
        response = await httpClient.get(
          `${resource}/announcement/${params.id}`,
          {
            ...params?.data,
          }
        );

        if (resource !== "vacancy") {
          response.data.data.product = {
            ...response.data.data.product,
            user: { ...response.data.data.user },
            filters: [...response.data.data.filters],
          };
        }
        return { data: response.data.data.product };
      }

      if (resource === "privacy-policy") {
        response = await httpClient.get(`${resource}`, {
          ...params?.data,
        });
        return { data: response };
      }

      if (resource === "social-network" || resource === "contact") {
        response = await httpClient.get(`${resource}`, {
          ...params?.data,
        });
        console.log("response", response.data.data.data[0]);
        return { data: response.data.data.data[0] };
      }
      response = await httpClient.get(`${resource}/${params.id}`, {
        ...params?.data,
      });
      return { data: response.data.data };
    },

    [CREATE]: async (resource, params) => {
      let response = null;
      console.log(resource);

      if (
        resource === "special-equipment" ||
        resource === "material" ||
        resource === "vacancy"
      ) {
        response = await httpClient.post(`${resource}/announcement`, {
          ...params?.data,
        });
        return { data: response };
      }
      if (resource === "social-network" || resource === "contact") {
        response = await httpClient.put(`${resource}`, {
          ...params?.data,
        });
        return { data: response };
      }

      if (
        resource === "special-equipment-categories" ||
        resource === "special-equipment-subcategories" ||
        resource === "material-categories" ||
        resource === "material-subcategories"
      ) {
        console.log("PRAMS", params);
        response = await httpClient.post(`${resource}`, {
          title: params.data.category.title,
          order: params?.data.category.order,
          seo: params?.data.seo,
          description: params.data.category.description,
          label: params.data.category.label,
          category_id: params.data.category_id,
        });
        return { data: response };
      }
      response = await httpClient.post(`${resource}`, {
        ...params?.data,
      });
      return { data: response.data.data };
    },

    [UPDATE]: async (resource, params) => {
      let response = null;
      if (
        resource === "special-equipment" ||
        resource === "material" ||
        resource === "vacancy"
      ) {
        response = await httpClient.put(
          `${resource}/announcement/${params.id}`,
          {
            ...params?.data,
          }
        );
        return { data: response };
      }

      if (resource === "social-network" || resource === "contact") {
        response = await httpClient.put(`${resource}`, {
          ...params?.data.seo,
        });
        return { data: response };
      }
      if (
        resource === "special-equipment-categories" ||
        resource === "special-equipment-subcategories" ||
        resource === "material-categories" ||
        resource === "material-subcategories"
      ) {
        console.log("PRAMS", params);
        response = await httpClient.put(`${resource}/${params.id}`, {
          title: params.data.category.title,
          order: params?.data.category.order,
          seo: params?.data.seo,
          description: params.data.category.description,
          label: params.data.category.label,
        });
        return { data: response };
      }
      if (resource === "main-page-block") {
        response = await httpClient.put(`slider/1`, {
          ...params?.data,
        });
        return { data: response };
      }

      response = await httpClient.put(`${resource}/${params.id}`, {
        ...params?.data,
      });
      return { data: response.data.data };
    },

    [UPDATE_MANY]: (resource, params) =>
      Promise.all(params.ids.map((id) => Promise.resolve())).then(() =>
        Promise.resolve()
      ),

    [DELETE]: async (resource, params) => {
      let response = null;

      if (
        resource === "special-equipment" ||
        resource === "material" ||
        resource === "vacancy"
      ) {
        response = await httpClient.delete(
          `${resource}/announcement/${params.id}`,
          {
            ...params?.data,
          }
        );
        return { data: response };
      }

      response = await httpClient.delete(`${resource}/${params.id}`, {
        ...params?.data,
      });
      return { data: response.data.data };
    },

    [DELETE_MANY]: async (resource, params) => {
      console.log(params);
      const response = await httpClient.delete(`${resource}`, {
        params,
      });
      return { data: response };
    },
  };
};
