import Vue from "vue";
import Vuex from "vuex";
import { regionsModule } from "./modules/regions.js";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: { regionsModule },
});
