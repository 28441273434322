<template>
  <v-file-input :value="value" @input="update" />
</template>

<script>
import Input from "vuetify-admin/src/mixins/input";

export default {
  mixins: [Input],
  props: {
    /**
     * Number to be edited.
     * @model
     */
    value: {
      type: String,
      default: null,
    },
  },
  methods: {
    decrement() {
      this.update(this.value);
    },
    increment() {
      this.update(this.value);
    },
  },
};
</script>
