<template>
  <div>
    <va-aside-layout class="custom-aside" :title="asideTitle" :opened="show">
      <!-- <special-equipment-show v-if="show" :item="item"></special-equipment-show> -->
      <vacancy-rights-form
        :id="id"
        :item="item"
        :action="actionType"
        @saved="onSaved"
      ></vacancy-rights-form>
    </va-aside-layout>
    <base-material-card :icon="resource.icon" :title="title">
      <va-list
        ref="list"
        disable-export="false"
        disable-global-search="false"
        disable-create-redirect
        @action="onAction"
      >
        <va-data-table
          :fields="fields"
          disable-edit-redirect
          @item-action="onAction"
        >
        </va-data-table>
      </va-list>
    </base-material-card>
  </div>
</template>

<script>
export default {
  components: {},
  props: ["resource", "title"],
  data() {
    return {
      fields: [
        { source: "id", sortable: true },
        { source: "title", sortable: true },
      ],

      asideTitle: null,
      id: null,
      item: null,
      show: false,
      actionType: null,
    };
  },

  methods: {
    async onAction({ id, title, action, item }) {
      console.log("CREATE BUTTON ", action);
      this.actionType = action;
      this.asideTitle = title;
      this.id = id;
      this.show = action === "show";
      this.item = item;
      if (action === "create") {
        this.id = null;
      }
    },
    onSaved() {
      this.asideTitle = null;
      this.$refs.list.fetchData();
    },
  },
};
</script>
