<template>
  <div>
    <va-aside-layout class="custom-aside" :title="asideTitle" :opened="show">
      <!-- <special-equipment-show v-if="show" :item="item"></special-equipment-show> -->
      <contact-form
        :item="item"
        :action="actionType"
        @saved="onSaved"
      ></contact-form>
    </va-aside-layout>
    <base-material-card :icon="resource.icon" :title="title">
      <va-list
        ref="list"
        disable-export="false"
        disable-global-search="false"
        @action="onAction"
      >
        <va-data-table
          :fields="fields"
          disable-edit-redirect
          @item-action="onAction"
        >
        </va-data-table>
      </va-list>
    </base-material-card>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { actionTypes } from "@/store/modules/regions";

export default {
  components: {},
  props: ["resource", "title"],
  data() {
    return {
      fields: [
        { source: "phone_number" },
        { source: "email" },
        { source: "address" },
      ],

      asideTitle: null,
      id: null,
      item: null,
      show: false,
      actionType: null,
    };
  },
  computed: {
    ...mapState("regionsModule", ["regions", "cities"]),
  },
  created() {
    this.loadRegions();
  },
  methods: {
    ...mapActions("regionsModule", {
      loadRegions: actionTypes.loadRegions,
      loadCities: actionTypes.loadCities,
    }),
    loadCitiesByRegion(id) {
      this.loadCities(id);
    },
    async onAction({ id, title, action, item }) {
      console.log("CREATE BUTTON ", action);
      this.actionType = action;
      this.asideTitle = title;
      this.id = id;
      this.show = action === "show";
      this.item = item;
      if (action === "create") {
        this.id = null;
      }
    },
    onSaved() {
      this.asideTitle = null;
      this.$refs.list.fetchData();
    },
  },
};
</script>
