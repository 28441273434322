<template>
  <div>
    <offer-agreement-form v-if="isUpdate" @saved="saved"></offer-agreement-form>
    <base-material-card v-else :icon="resource.icon" :title="'Main Page'">
      <v-container>
        <a :href="fileLink" class="d-flex mb-2" target="_blank">{{
          fileLink
        }}</a>
        <v-btn color="primary" @click="update">Изменить</v-btn>
      </v-container>
    </base-material-card>
  </div>
</template>

<script>
export default {
  components: {},
  props: ["resource", "title"],
  data() {
    return {
      file: "",
      isUpdate: false,
    };
  },
  computed: {
    fileLink() {
      return this.file?.length ? this.file[0]?.file : "Нет файла";
    },
  },
  watch: {
    isUpdate: {
      handler() {
        window.scrollTo(0, 0);
      },
    },
  },
  created() {
    this.getOfferAgreement();
  },
  methods: {
    getOfferAgreement() {
      this.$admin.get("/offer-agreement").then((response) => {
        this.file = response.data.data.data;
      });
    },
    update() {
      this.isUpdate = true;
    },
    onSaved() {
      this.asideTitle = null;
      this.$refs.list.fetchData();
    },
    saved() {
      this.isUpdate = false;
      this.getOfferAgreement();
    },
  },
};
</script>

<style lang="scss">
.main-page {
  padding-top: 20px;
  &__content {
    margin-bottom: 30px;
  }

  &__title {
    font-size: 18px;
    line-height: 120%;
    margin-bottom: 5px;
  }

  &__text {
    font-size: 20px;
    line-height: 120%;
  }

  &__image {
    width: 500px;
    height: 500px;
  }
}
</style>
