<template>
  <va-form :id="id" :item="item" disable-redirect @saved="$emit('saved')">
    <va-text-input source="category.title"></va-text-input>
    <va-number-input source="category.parent_id"></va-number-input>
    <va-number-input source="category.order"></va-number-input>

    <hr />
    <br />
    <h2>SEO</h2>
    <va-text-input source="seo.title" label="Называние"></va-text-input>
    <va-text-input source="seo.description" label="Описание"></va-text-input>
    <va-text-input source="seo.content" label="Ключевые слова"></va-text-input>

    <h2>Описание</h2>
    <va-text-input source="category.label" label="Загаловок"></va-text-input>
    <va-rich-text-input
      source="category.description"
      label="Описание"
    ></va-rich-text-input>
    <va-save-button v-if="isShowSaveButton"></va-save-button>
  </va-form>
</template>

<script>
export default {
  props: ["id", "item", "action"],

  computed: {
    isShowSaveButton() {
      return this.action !== "show";
    },
  },
};
</script>
