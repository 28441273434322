import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import i18n from "./i18n";
import vuetify from "./plugins/vuetify";
import admin from "./plugins/admin";
import VueMask from "v-mask";
import "./plugins/i18n";
import "./plugins/base";
import "./plugins/chartist";
import "./sass/overrides.sass";
import "./assets/style.scss";
import ImageInput from "./components/inputs/ImageInput.vue";

Vue.component("ImageInput", ImageInput);

Vue.config.productionTip = false;
Vue.use(VueMask);
new Vue({
  router,
  store,
  i18n,
  vuetify,
  admin,
  render: (h) => h(App),
}).$mount("#app");
