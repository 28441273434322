<template>
  <div>
    <about-form
      v-if="isUpdateAbout"
      :about="about"
      @saved="onSaved"
    ></about-form>
    <base-material-card v-else :icon="resource.icon" :title="'About'">
      <v-container>
        <div v-if="about" class="about">
          <h3 class="about__title">main</h3>
          <div class="about__content">
            <img class="about__content-img" :src="about.main.image" alt="" />
            <div class="about__content-item">
              <p class="about__content-title">
                <span>title</span> {{ about.main.title }}
              </p>
              <p class="about__content-subtitle">
                <span>subtitle</span> {{ about.main.subtitle }}
              </p>
              <p class="about__content-text">
                <span>text</span> {{ about.main.text }}
              </p>
            </div>
          </div>
        </div>
        <div class="about">
          <h3 class="about__title">mission</h3>
          <div class="about__content">
            <img class="about__content-img" :src="about.mission.image" alt="" />
            <p class="about__content-text">
              <span>text</span>{{ about.mission.text }}
            </p>
          </div>
        </div>
        <div class="about about__values">
          <h3 class="about__title">values</h3>
          <div class="about__values-inner">
            <div
              class="about__values-item"
              v-for="(item, index) in about.values"
              :key="index"
            >
              <div class="about__values-content">
                <img class="about__values-img" :src="item.image" alt="" />
                <p class="about__values-title">
                  <span>title</span>{{ item.title }}
                </p>
                <p class="about__values-text">
                  <span>text</span>{{ item.text }}
                </p>
                <p class="about__values-imagevalues">
                  <span>imageValues</span
                  >{{ item.imageValues ? item.imageValues : "нет" }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <v-btn color="primary" @click="updateAbout">Изменить</v-btn>
      </v-container>
    </base-material-card>
  </div>
</template>

<script>
export default {
  components: {},
  props: ["resource", "title"],
  data() {
    return {
      fields: [],

      asideTitle: null,
      id: null,
      item: null,
      show: false,
      actionType: null,

      about: null,
      isUpdateAbout: false,
    };
  },
  computed: {},
  watch: {
    isUpdateAbout: {
      handler() {
        window.scrollTo(0, 0);
      },
    },
  },
  created() {
    this.$admin.http.get("/about-us").then((response) => {
      console.log(response.data.data.data);
      this.about = response.data.data.data;
    });
  },
  methods: {
    updateAbout() {
      this.isUpdateAbout = true;
    },
    onSaved() {
      this.asideTitle = null;
      this.$admin.http.get("/about-us").then((response) => {
        console.log(response.data.data.data);
        this.about = response.data.data.data;
      });
      this.isUpdateAbout = false;
    },
  },
};
</script>

<style lang="scss">
.about {
  position: relative;
  & + & {
    margin-top: 30px;
  }
  &__title {
    font-size: 25px;
    line-height: 120%;
    margin-bottom: 20px;
  }

  &__content {
    display: flex;

    &-item {
      p {
        span {
          display: block;
          font-size: 16px;
        }
      }
    }
    &-title {
      font-size: 22px;
      line-height: 120%;
      margin-bottom: 20px;
      span {
        display: block;
        font-size: 16px;
      }
    }
    &-subtitle {
      font-size: 20px;
      line-height: 120%;
      margin-bottom: 20px;
      span {
        display: block;
        font-size: 16px;
      }
    }
    &-text {
      font-size: 18px;
      line-height: 120%;
      margin-bottom: 20px;
      span {
        display: block;
        font-size: 16px;
      }
    }

    &-img {
      width: 500px;
      height: 500px;
      object-fit: cover;
      border-radius: 20px;
      margin-bottom: 20px;
      margin-right: 20px;
    }
  }

  &__values {
    &-inner {
      display: flex;
      flex-wrap: wrap;
    }

    &-item {
      margin-right: 30px;
      margin-bottom: 20px;
      p {
        span {
          display: block;
          font-size: 16px;
        }
      }
    }

    &-img {
      width: 300px;
      height: 300px;
      object-fit: cover;
      border-radius: 20px;
      margin-bottom: 20px;
      margin-right: 20px;
    }

    &-title {
      font-size: 20px;
      line-height: 120%;
      margin-bottom: 20px;
    }

    &-text {
      font-size: 18px;
      line-height: 120%;
      margin-bottom: 20px;
    }
    &-imagevalue {
      font-size: 18px;
      line-height: 120%;
    }
  }
}
</style>
