<template>
  <div>
    <va-aside-layout class="custom-aside" :title="asideTitle" :opened="show">
      <!-- <special-equipment-show v-if="show" :item="item"></special-equipment-show> -->
      <special-equipment-form
        :id="id"
        :item="item"
        :action="actionType"
        @saved="onSaved"
      ></special-equipment-form>
    </va-aside-layout>
    <base-material-card :icon="resource.icon" :title="title">
      <v-container fluid>
        <v-row align="center">
          <v-col cols="12" sm="6">
            <v-select
              :items="regions"
              chips
              label="Регион"
              item-text="region"
              item-value="id"
              outlined
              @change="loadCitiesByRegion"
            ></v-select>
          </v-col>
          <v-col cols="12" sm="6">
            <v-select
              :items="cities"
              chips
              label="Город"
              item-text="city"
              item-value="id"
              outlined
              @change="getFiltredData"
            ></v-select>
          </v-col>
        </v-row>
      </v-container>
      <va-list ref="list" disable-create-redirect @action="onAction">
        <va-data-table
          :fields="fields"
          disable-create-redirect
          disable-show-redirect
          disable-edit-redirect
          @item-action="onAction"
        >
        </va-data-table>
      </va-list>
    </base-material-card>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { actionTypes } from "@/store/modules/regions";

export default {
  components: {},
  props: ["resource", "title"],
  data() {
    return {
      fields: [
        { source: "id", sortable: true },
        { source: "title", sortable: true },
        { source: "price" },
        { source: "location.city", label: "Город" },
        { source: "location.id", label: "ИД ГОРОДА" },
      ],

      asideTitle: null,
      id: null,
      item: null,
      show: false,
      actionType: null,
    };
  },
  computed: {
    ...mapState("regionsModule", ["regions", "cities"]),
  },
  created() {
    this.loadRegions();
  },
  methods: {
    ...mapActions("regionsModule", {
      loadRegions: actionTypes.loadRegions,
      loadCities: actionTypes.loadCities,
    }),
    ...mapActions({
      getList: "api/getList",
    }),
    loadCitiesByRegion(id) {
      this.loadCities(id);
    },
    async getFiltredData() {
      await this.getList({
        resource: "special-equipment",
        params: {
          pagination: {
            page: 1,
            perPage: 5,
          },
          region_id: 1,
        },
      });
    },
    async onAction({ id, title, action, item }) {
      console.log("CREATE BUTTON ", action);
      this.actionType = action;
      this.asideTitle = title;
      this.id = id;
      this.show = action === "show";
      this.item = item;
      if (action === "create") {
        this.id = null;
      }
    },
    onSaved() {
      this.asideTitle = null;
      this.$refs.list.fetchData();
    },
  },
};
</script>
