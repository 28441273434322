import { getRegions, getCities } from "@/api/region";
export const mutationTypes = {
  loadRegionsSuccess: "mutation/loadRegionsSuccess",
  loadRegionsStart: "mutation/loadRegionsStart",
  loadRegionsFailure: "mutation/loadRegionsFailure",

  loadCitiesStart: "mutation/loadCitiesStart",
  loadCitiesSuccess: "mutation/loadCitiesSuccess",
  loadCitiesFailure: "mutation/loadCitiesFailure",
};
export const actionTypes = {
  loadRegions: "action/loadRegions",
  loadCities: "action/loadCities",
};

export const regionsModule = {
  namespaced: true,
  state: () => ({
    regions: null,
    cities: null,
    error: null,
    isLoading: false,
  }),
  mutations: {
    [mutationTypes.loadRegionsStart](state) {
      state.isLoading = true;
    },
    [mutationTypes.loadRegionsSuccess](state, payload) {
      state.isLoading = false;
      state.regions = payload;
    },
    [mutationTypes.loadRegionsFailure](state, payload) {
      state.isLoading = false;
      state.error = payload;
    },

    [mutationTypes.loadCitiesStart](state) {
      state.isLoading = true;
    },
    [mutationTypes.loadCitiesSuccess](state, payload) {
      state.isLoading = false;
      state.cities = payload;
    },
    [mutationTypes.loadCitiesFailure](state, payload) {
      state.isLoading = false;
      state.error = payload;
    },
  },
  actions: {
    [actionTypes.loadRegions](context) {
      console.log(this);
      context.commit(mutationTypes.loadRegionsStart);
      return new Promise((resolve) => {
        getRegions()
          .then((response) => {
            context.commit(
              mutationTypes.loadRegionsSuccess,
              response.data.data.regions
            );
            resolve(response);
          })
          .catch((e) => {
            context.commit(mutationTypes.loadRegionsFailure, e);
          });
      });
    },

    [actionTypes.loadCities]({ commit }, regionId) {
      commit(mutationTypes.loadCitiesStart);
      return new Promise((resolve) => {
        getCities(regionId)
          .then((response) => {
            console.log("CITIES", response.data.data);
            commit(mutationTypes.loadCitiesSuccess, response.data.data);
            resolve(response);
          })
          .catch((e) => {
            commit(mutationTypes.loadCitiesFailure, e);
          });
      });
    },
  },
  modules: {},
};
