<template>
  <va-form :id="id" :item="item" disable-redirect @saved="$emit('saved')">
    <v-text-field
      label="subtitle"
      source="title"
      v-model="news.title"
      required
    ></v-text-field>
    <v-text-field
      source="subtitle"
      v-model="news.subtitle"
      label="subtitle"
      type="text"
      required
    ></v-text-field>
    <va-rich-text-input v-model="news.description"></va-rich-text-input>
    <v-file-input
      accept="image/*"
      v-model="rawImage"
      @change="covertImageToBase64"
    />
    <v-img v-if="item" :src="item.image" />

    <v-btn color="blue" @click.prevent="save">mdi-save</v-btn>
  </va-form>
</template>

<script>
export default {
  props: ["id", "item"],
  data() {
    return {
      news: { title: "", subtitle: "", image: "", description: "" },
      rawImage: null,
      isConvertDone: false,
    };
  },
  created() {
    this.news = { ...this.item };
  },
  methods: {
    save() {
      console.log(this.description);
      if (!this.item) {
        this.$admin.http
          .post("/news", this.news)
          .then(() => {
            this.$emit("saved");
          })
          .catch((e) => {
            this.$store.commit("messages/showToast", {
              color: "red",
              message: e.response.data.message,
            });
          });
        return;
      }
      // this.news = { ...this.item };
      let updatedData = this.news;
      if (!this.rawImage) {
        this.news.image = { link: this.item.image };
        updatedData = this.news;
      } else {
        updatedData = this.news;
      }

      this.$admin.http.put(`/news/${this.item.id}`, updatedData).then(() => {
        this.$emit("saved");
      });

      // this.$emit("saved", this.title);
    },
    covertImageToBase64() {
      console.log("DDDD", this.rawImage);
      const file = this.rawImage;
      const ext = file?.name.split(".")[1];
      const reader = new FileReader();
      let base64Str = "";

      reader.onload = () => {
        base64Str = reader.result;
        // console.log(base64Str)
        this.news.image = { [ext]: base64Str };
        console.log(this.news.image);
        this.isConvertDone = true;
      };
      reader.readAsDataURL(file);
    },
  },
};
</script>
