<template>
  <va-form :id="id" :item="item" disable-redirect @saved="$emit('saved')">
    <v-text-field
      label="title"
      source="title"
      v-model="slider.title"
      required
    ></v-text-field>
    <v-text-field
      source="subtitle"
      v-model="slider.subtitle"
      label="subtitle"
      type="text"
      required
    ></v-text-field>
    <v-text-field
      source="link"
      v-model="slider.link"
      label="link"
      type="text"
      required
    ></v-text-field>
    <v-file-input
      accept="image/*"
      v-model="rawImage"
      @change="covertImageToBase64"
    />
    <v-img v-if="slider" :key="slider" :src="slider.image" />
    <v-btn class="slider__save-btn" color="blue" @click.prevent="save"
      >mdi-save</v-btn
    >
  </va-form>
</template>

<script>
export default {
  props: ["id", "item", "action"],
  data() {
    return {
      slider: { title: "", subtitle: "", image: "", link: "" },
      rawImage: null,
      isConvertDone: false,
    };
  },
  created() {
    this.slider = { ...this.item };
  },
  methods: {
    save() {
      let slider = {
        title: this.slider.title,
        subtitle: this.slider.subtitle,
        image: this.slider.image,
        link: this.slider.link,
      };
      if (!this.item) {
        this.$admin.http
          .post("/slider", slider)
          .then(() => {
            this.$emit("saved");
          })
          .catch((e) => {
            this.$store.commit("messages/showToast", {
              color: "red",
              message: e.response.data.message,
            });
          });
        return;
      }
      // let updatedData = this.slider;
      // if (!this.rawImage) {
      //   slider.image = { link: this.item.image };
      //   updatedData = slider;
      // } else {
      //   updatedData = slider;
      // }

      this.$admin.http.put(`/slider/${this.item.id}`, slider).then(() => {
        this.$emit("saved");
      });
    },
    covertImageToBase64() {
      console.log("DDDD", this.rawImage);
      const file = this.rawImage;
      const reader = new FileReader();
      let base64Str = "";

      reader.onload = () => {
        base64Str = reader.result;
        // console.log(base64Str)
        this.slider.image = base64Str;
        console.log(this.slider.image);
        this.isConvertDone = true;
      };
      reader.readAsDataURL(file);
    },
  },
};
</script>

<style lang="scss" scoped>
.slider__save {
  &-btn {
    margin-top: 20px;
  }
}
</style>
