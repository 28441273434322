<template>
  <div>
    <main-page-form
      v-if="isUpdateMain"
      :item="main[0]"
      @saved="isUpdateMain = false"
    ></main-page-form>
    <base-material-card v-else :icon="resource.icon" :title="'Main Page'">
      <v-container>
        <div v-for="(item, index) in main" :key="index" class="main-page">
          <div class="main-page__content">
            <h5 class="main-page__title">title</h5>
            <p class="main-page__text">{{ item.title }}</p>
          </div>
          <div class="main-page__content">
            <h5 class="main-page__title">subtitle</h5>
            <p class="main-page__text">{{ item.subtitle }}</p>
          </div>
          <div class="main-page__content">
            <h5 class="main-page__title">image</h5>
            <img class="main-page__image" :src="item.image" alt="" />
          </div>
        </div>
        <v-btn color="primary" @click="updateAbout">Изменить</v-btn>
      </v-container>
    </base-material-card>
  </div>
</template>

<script>
export default {
  components: {},
  props: ["resource", "title"],
  data() {
    return {
      fields: [],

      asideTitle: null,
      id: null,
      item: null,
      show: false,
      actionType: null,

      main: [],
      isUpdateMain: false,
    };
  },
  computed: {},
  watch: {
    isUpdateMain: {
      handler() {
        window.scrollTo(0, 0);
      },
    },
  },
  created() {
    this.$admin.http.get("/main-page-block").then((response) => {
      console.log(response.data.data.data);
      this.main = response.data.data.data;
    });
  },
  methods: {
    updateAbout() {
      this.isUpdateMain = true;
    },
    onSaved() {
      this.asideTitle = null;
      this.$refs.list.fetchData();
    },
  },
};
</script>

<style lang="scss">
.main-page {
  padding-top: 20px;
  &__content {
    margin-bottom: 30px;
  }

  &__title {
    font-size: 18px;
    line-height: 120%;
    margin-bottom: 5px;
  }

  &__text {
    font-size: 20px;
    line-height: 120%;
  }

  &__image {
    width: 500px;
    height: 500px;
  }
}
</style>
