<template>
  <va-show :item="item">
    <va-field source="title"></va-field>
    <va-field source="subtitle"></va-field>
    <v-img v-if="item" :src="item.image" />
  </va-show>
</template>

<script>
export default {
  props: ["item"],
};
</script>
