import axios from "axios";

const baseURL =
  process.env.VUE_APP_API_URL || "https://api.megarent.kz/api/admin";

const token = localStorage.getItem("token");
const headers = {
  "X-Requested-With": "XMLHttpRequest",
  Accept: "applicatoin/json",
};

if (token) {
  headers["Authorization"] = `Bearer ${token}`;
}

const api = axios.create({
  baseURL,
  headers,
});

export default api;
