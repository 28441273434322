/* eslint-disable no-unused-vars */

import {
  LOGIN,
  LOGOUT,
  CHECK_AUTH,
  CHECK_ERROR,
  GET_NAME,
  GET_EMAIL,
  GET_PERMISSIONS,
} from "vuetify-admin/src/providers/auth/actions";

/**
 * Implement compatible auth provider
 */
let user = {};
export default (httpClient) => {
  return {
    [LOGIN]: async ({ username, password }) => {
      const response = await httpClient.post("/auth/login", {
        email: username,
        password,
      });
      console.log(response.data);
      user = response.data.data;
      localStorage.setItem("token", response.data.data.token);
      return response.data;
    },
    [LOGOUT]: () => {
      localStorage.removeItem("token");
    },
    [CHECK_AUTH]: async () => {
      return localStorage.getItem("token")
        ? Promise.resolve({
            data: user,
          })
        : Promise.reject();
    },
    [CHECK_ERROR]: ({ status }) => Promise.resolve(),
    [GET_NAME]: (u) => u.name,
    [GET_EMAIL]: (u) => u.email,
    [GET_PERMISSIONS]: (u) => u.permissions,
  };
};
